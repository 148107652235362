<template>
  <div class="home">

    <div class="container" style="margin-top: 50px; margin-bottom: 50px;">
      <div class="text-center">
        <h2 class="title">
            Félicitations
        </h2>
        <h3 class="subtitle mt-4">
          Vous êtes bien inscrit à la réunion<br>
          ERREUR MÉDICAMENTEUSE ET RESPONSABILITÉ<br>
          JURIDIQUE - CONNAÎTRE, ANTICIPER, PRÉVENIR
        </h3>
      </div>

      <div class="text-center mt-4">
        <img
          id='brand_logo'
          :src="require('@/assets/images/zentiva_logo.png')"
          alt="Zentiva"
          width="200px"
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmView',
}
</script>
