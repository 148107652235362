import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class APIService {

  createParticipant (data) {
    const url = apiBaseUrl + `/participants/`
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(
      url,
      data,
      headers
    )
  }
}
