<template>
  <div id="app">
    <div class="header-div text-center">
      <img
        id='header'
        :src="require('@/assets/images/header.png')"
        alt="Header"
      >
    </div>
    <globalErrorModalComponent />
    <LoadingComponent />
    <router-view/>
  </div>
</template>

<script>
import globalErrorModalComponent from '@/components/globalErrorModalComponent'
import LoadingComponent from '@/components/LoadingComponent'

export default {
  name: 'App',
  components : {
    globalErrorModalComponent,
    LoadingComponent
  },
}
</script>
<style>

@font-face {
  font-family: "ItcBold";
  font-style: bold;
  src: url("./assets/fonts/itc-avant-garde-gothic-std-bold.otf");
  font-display: swap;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-div {
  margin-top: 20px;
}

#header {
  width: 80%;
}

.title {
  color: #01a68a;
  font-weight: 800;
  font-size: 26px;
  font-family: ItcBold, Avenir, Helvetica, Arial, sans-serif;
}

.subtitle {
  color:#007bc0;
  font-weight: 400;
  font-size: 22px;
  font-style: italic;
}

@media (max-width: 760px) {
  .title {
    font-size: 22px;
  }

  .subtitle {
    font-size: 16px;
  }
}




</style>
