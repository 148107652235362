import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    isGlobalErrorModal: false,
    isAPILoading: false,
  },
  getters: {
    isAPILoading: state => !!state.isAPILoading,
  },
  mutations: {
    set (state, [variable, value]) {
      state[variable] = value
    },
    openGlobalErrorModal (state) {
      state.isGlobalErrorModal = true
    },
    closeGlobalErrorModal (state) {
      state.isGlobalErrorModal = false
    },
    loadFromAPI (state) {
      state.isAPILoading = true
    },
    endLoadingFromAPI (state) {
      state.isAPILoading = false
    },
  },
  actions: {
  },
  modules: {
  }
})
