import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {
  BadgePlugin, ButtonGroupPlugin, CardPlugin, LayoutPlugin, ModalPlugin, ToastPlugin, DropdownPlugin,
  NavPlugin, NavbarPlugin, ButtonPlugin,
  FormPlugin, FormCheckboxPlugin, FormGroupPlugin,
  FormInputPlugin, InputGroupPlugin, FormSelectPlugin,
  FormFilePlugin, FormTextareaPlugin, SpinnerPlugin,
  TablePlugin, TabsPlugin, ImagePlugin, FormRadioPlugin,
  FormDatepickerPlugin, PopoverPlugin, TooltipPlugin, SidebarPlugin
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.config.productionTip = false

Vue.use(BadgePlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(CardPlugin)
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(ToastPlugin)
Vue.use(DropdownPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormTextareaPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ImagePlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(PopoverPlugin)
Vue.use(TooltipPlugin)
Vue.use(SidebarPlugin)

Vue.component('custom-loading-component', Loading)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
