<template>
  <div class="home">

    <div class="container" style="margin-top: 30px; margin-bottom: 50px;">
      <div class="text-center">
        <h2 class="title">
          ERREUR MÉDICAMENTEUSE ET RESPONSABILITÉ <br>
          JURIDIQUE - CONNAÎTRE, ANTICIPER, PRÉVENIR
        </h2>
        <h3 class="subtitle">
          Aux Hospices Civils de Beaune (salle de conférence)<br>
          le 13 décembre 2022 de 13h45 à 16h00
        </h3>
      </div>

      <div class="form">
        <div class="row">
          <div class="col-12 col-md-5">
            <span>Je souhaite participer à la réunion :</span>
          </div>
          <div class="col-12 col-md-7">
            <b-form-radio-group
              id="participate-checkbox"
              v-model="participant.participate"
              :options="participateOptions"
              name="participate-checkbox"
            ></b-form-radio-group>
          </div>
        </div>

        <div class="row" style="margin-top: 40px;">
          <div class="col-12 col-md-3">
            <span>Nom<sup>*</sup></span>
          </div>
          <div class="col-12 col-md-9">
            <b-form-input
              v-model="participant.last_name"
              type="text"
              maxlength="255"
              @input="$v.participant.last_name.$touch()"
              :state="$v.participant.last_name.$dirty ? !$v.participant.last_name.$error : null"
            ></b-form-input>
          </div>
        </div>
        <p v-if="$v.participant.last_name.$dirty ? $v.participant.last_name.$error : null " class="text-error">
          Votre nom doit comporter entre 2 et 255 caractères
        </p>

        <div class="row mt-4">
          <div class="col-12 col-md-3">
            <span>Prénom<sup>*</sup></span>
          </div>
          <div class="col-12 col-md-9">
            <b-form-input
              v-model="participant.first_name"
              type="text"
              maxlength="255"
              @input="$v.participant.first_name.$touch()"
              :state="$v.participant.first_name.$dirty ? !$v.participant.first_name.$error : null"
            ></b-form-input>
          </div>
        </div>
        <p v-if="$v.participant.first_name.$dirty ? $v.participant.first_name.$error : null " class="text-error">
          Votre prénom doit comporter entre 2 et 255 caractères
        </p>

        <div class="row mt-4">
          <div class="col-12 col-md-3">
            <span>Adresse e-mail<sup>*</sup></span>
          </div>
          <div class="col-12 col-md-9">
            <b-form-input
              v-model="participant.email"
              type="text"
              maxlength="255"
              @input="$v.participant.email.$touch()"
              :state="$v.participant.email.$dirty ? !$v.participant.email.$error : null"
            ></b-form-input>
          </div>
        </div>
        <p v-if="$v.participant.email.$dirty ? $v.participant.email.$error : null " class="text-error">
          Veuillez saisir un email valide
        </p>

        <div class="row mt-4">
          <div class="col-12 col-md-3">
            <span>Téléphone</span>
          </div>
          <div class="col-12 col-md-9">
            <b-form-input
              v-model="participant.phone_number"
              type="tel"
              maxlength="10"
              @input="$v.participant.phone_number.$touch()"
              :state="$v.participant.phone_number.$dirty ? !$v.participant.phone_number.$error : null"
            ></b-form-input>
          </div>
        </div>
      </div>

      <p v-if="$v.participant.phone_number.$dirty ? $v.participant.phone_number.$error : null " class="text-error">
        Veuillez saisir un numéro de téléphone composé de 10 chiffres
      </p>

      <div class="information">
        <div>
          <p>
            <i><sup>*</sup>Champs obligatoires pour votre inscription</i>
          </p>
        </div>


        <div class="mt-4">
          <p>
            Lors de cette réunion, une collation sera servie pour un montant maximum de 15€ TTC.<br><br>
            Cette invitation valant convention, elle a fait l’objet d’une transmission par Zentiva France au Conseil de l’Ordre compétent,
            conformément aux dispositions de l’article L. 1453-7 et suivants du
            Code de la Santé Publique.<br>
            Conformément aux dispositions légales et réglementaires en matière de transparence, Zentiva
            France rendra publics les montants des frais pris en charge dans le cadre de la réalisation de cette
            manifestation, selon les modalités fixées par la réglementation sur la Transparence des liens
            d’intérêts.
          </p>
        </div>
      </div>

      <div class="text-center mt-4">
        <button
          class="submit-button"
          :disabled="isInvalidInformation || participant.participate !== true"
          @click="createParticipant"
        >
          VALIDER
        </button>
      </div>

      <div class="text-right mt-4">
        <img
          id='brand_logo'
          :src="require('@/assets/images/zentiva_logo.png')"
          alt="Zentiva"
          width="200px"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, integer, required, minLength, maxLength } from 'vuelidate/lib/validators'


import { APIService } from '@/api/Api'

const api = new APIService()


export default {
  name: 'HomeView',
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      participateOptions: [
        { text: 'Oui', value: true },
        { text: 'Non', value: false },
      ],
      participant: {
        participate: null,
        last_name: '',
        first_name: '',
        email: '',
        phone_number: ''
      }

    }
  },
  computed: {
    isInvalidInformation () {
      if (this.$v.participant.$invalid) {
        return true
      }
      return false
    }
  },

  validations: {
    participant: {
      participate: {
        required,
      },
      last_name: {
        minLength: minLength(2),
        maxLength: maxLength(255),
        required
      },
      first_name: {
        minLength: minLength(2),
        maxLength: maxLength(255),
        required
      },
      email: {
        minLength: minLength(2),
        maxLength: maxLength(255),
        required,
        email
      },
      phone_number: {
        minLength: minLength(10),
        maxLength: maxLength(10),
        integer
      }
    }
  },

  methods: {
    createParticipant () {
      this.$store.commit('loadFromAPI')
      api.createParticipant(this.participant)
      .then(() => {
        this.$router.push('/confirmation')
      })
      .catch((error) => {
        throw new Error('createParticipant createParticipant API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },
  }
}
</script>

<style>

.form {
  margin-top: 40px;
}

.information {
  margin-top: 20px;
  font-size: 12px;
}

.submit-button {
  border: none;
  background-color: #01a68a;
  color: white;
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.submit-button:disabled,
.submit-button[disabled]{
  opacity: .65;
}

.text-error {
  font-size: 9px;
  color: red;
  margin-top: 10px;
}
</style>
